import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import { Box } from '@makerdao/ui-components-core'
import { defaultLocale } from '../i18n'

const NotFoundPage = () => (
  <Layout hideFooter={true} locale={defaultLocale}>
    <Box mt="18rem" mx="auto" maxWidth="650px" textAlign="center">
      <Box mb="1.6rem">
        <Box
          fontWeight={500}
          fontSize="4.5rem"
          color="#000000"
          letterSpacing="0.4px"
        >
          Page not found
        </Box>
      </Box>
      <p style={{ color: '#000000', fontSize: '21px' }}>
        The page you’re looking for is either missing or it doesn’t exist.
        Double-check that the web address is correct.
      </p>
      <Box height="27px" />
      <Link to="/" style={{ fontSize: '20px', fontWeight: 500 }}>
        Go to homepage
      </Link>
    </Box>
  </Layout>
)

export default NotFoundPage
